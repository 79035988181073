/*
* 업무구분:
* 화면 명:
* 화면 설명:
* 화면 접근권한:
*/
<template>
  <!-- 라이프맴핑_차트 영역 S -->
  <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="nw-chart-area">
    <div class="chart-title-box fex-clumn">
      <strong class="chart-tit">총 수입</strong>
      <div class="total-box fex-rw">
        <div class="total-sum fex-rw">
          <span class="sun-txt"><b>{{ rtmBefTotIncm }}</b>억원</span>  
          <div class="btn-edits">
            <mo-button @click="fn_infoEdit" class="btn-edit">정보수정</mo-button>
          </div>
        </div>
        <div class="switch-box">
          <span class="txt">리스크표시</span>
          <mo-switch v-model="riskOnOff" class="ns-switch" labeled/>
        </div>
      </div>
    </div>
    <div class="chart-area fex-clumn">
      <div class="chart-left-amount fex-clumn">
        <span class="top">{{ Number(pData.rtmBefMntBasIncmAmt) + Number(pData.rtmBefMntAddmIncmAmt) }}만원</span>
        <span class="center">{{ incmAmt*2 }}만원</span>
        <span class="bottom">{{ incmAmt }}만원</span>
      </div>
      <div class="chart-graph-type">
        <div class="chart-item-box">
          <div class="chart-item itm-ty1 itm-sTy1">
            <strong class="tit">은퇴 전 총 소득</strong>
              <div class="item-con">
              <span class="total-sum"><b>{{ rtmBefTotIncm }}</b>억원</span>  
              <span class="detail-item-txt">
                <span class="txt">=</span>
                <span class="item-txt">
                월 <b>{{ $bizUtil.numberWithCommas(Number(pData.rtmBefMntBasIncmAmt) + Number(pData.rtmBefMntAddmIncmAmt)) }}</b>만원
                </span>
                <span class="txt">*</span>
                <span class="item-txt">
                  <b class="fwn">12</b>개월
                </span> 
                <span class="txt">*</span>
                <span class="item-txt">
                  <b>{{ Number(pData.custRtmAge) - Number(pData.custCurAge) }}</b>년
                </span>     
              </span>  
              </div>
          </div>
        </div>
        <!-- 리스크 영역 : 사망리스크 -->
        <div class="chart-risk-box" v-if="riskOnOff">
          <a href="#" class="risk-link" @click="fn_openPdf">
            <span class="risk-txt">사망리스크</span>
          </a>
        </div>
      </div>
      <div class="chart-bottom-age">
        <span>{{ pData.custCurAge }}세</span>
        <span>{{ cAge }}세</span>
        <span>{{ pData.custRtmAge }}세</span>
      </div>
    </div>
  </ur-box-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT705D",
    screenId: "MSPCT705D",
    components: {
    },
    props: {
      pData: {
        type: Object
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // 은퇴전총소득
      // ((월기본소득 + 월추가소득) * 12 * (은퇴나이 - 현재나이)) * 0.0001
      rtmBefTotIncm() {
        return (((Number(this.pData.rtmBefMntBasIncmAmt) + Number(this.pData.rtmBefMntAddmIncmAmt)) * 12 * 
          (Number(this.pData.custRtmAge) - Number(this.pData.custCurAge))
          ) * 0.0001).toFixed(1)
      },
      cAge() {
        return ((Number(this.pData.custRtmAge) + Number(this.pData.custCurAge)) / 2).toFixed(0)
      },
      incmAmt() {
        return ((Number(this.pData.rtmBefMntBasIncmAmt) + Number(this.pData.rtmBefMntAddmIncmAmt)) / 3).toFixed(0)
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        riskOnOff:false,
        lv_SelectedSingleItem: null,
        // swipe-tap 에서 선택된 대분뷰명=> 라이브러리 or 사랑On도움방 대분류 선택시 안드로이드 네이티브 pdf뷰에서 "SMS" 메뉴 안보이기 
        lv_SelcectedClsfSytmNm: ''
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_infoEdit() {
        // let objParams = {}
        // objParams.step = '1'
        // objParams.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.pData)
          
        // this.$router.push( { name: 'MSPCT703M', params : objParams} )
        this.$emit('openStepInpt', '1')
      },
      fn_openPdf() {
        this.$emit('openPdf', {untySlmtClsfCd: 'E401'})
      }


    }
  }

</script>
<style scoped>

</style>


