/*
* 업무구분:
* 화면 명:
* 화면 설명:
* 화면 접근권한:
*/
<template>
  <ur-page-container title="라이프맵핑" :show-title="true" type="subpage" @on-header-left-click="fn_back">
    <!-- 라이프맴핑 전체 S -->
    <ur-box-container direction="column" alignV="start" alignH="start" componentid="" class="nw-qw-wrap nw-life-wrap">
      <!-- 라이프맴핑 결과 S -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column"  class="ns-add-area nw-life-result pal0">
        <!-- 라이프맴핑_상단 버튼 영역 S -->
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
          <mo-segment-wrapper solid primary v-model="stepButton" class="ns-btn-s2-4 nw-segment-button">
            <mo-segment-button value="1">은퇴전 수입</mo-segment-button>
            <mo-segment-button value="4">은퇴후 수입</mo-segment-button>
            <mo-segment-button value="2">은퇴전 지출</mo-segment-button>
            <mo-segment-button value="3">은퇴후 지출</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>

        <!-- 라이프맴핑_차트 영역 S -->
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="nw-chart-area" v-if="(stepButton == '1' && stepInptYn1) || (stepButton == '2' && stepInptYn2) || (stepButton == '3' && stepInptYn3) || (stepButton == '4' && stepInptYn4)">
          <MSPCT705D :pData="ctLifeMappRsltInfoSVO" @openStepInpt="fn_openStepInpt" @openPdf="fn_openPdf" v-if="stepButton == '1' && stepInptYn1"/><!-- 은퇴전수입 -->
          <MSPCT706D :pData="ctLifeMappRsltInfoSVO" @openStepInpt="fn_openStepInpt" @openPdf="fn_openPdf" v-else-if="stepButton == '2' && stepInptYn2"/><!-- 은퇴전지출 -->
          <MSPCT707D :pData="ctLifeMappRsltInfoSVO" @openStepInpt="fn_openStepInpt" @openPdf="fn_openPdf" v-else-if="stepButton == '3' && stepInptYn3"/><!-- 은퇴후지출 -->
          <MSPCT708D :pData="ctLifeMappRsltInfoSVO" @openStepInpt="fn_openStepInpt" @openPdf="fn_openPdf" v-else-if="stepButton == '4' && stepInptYn4"/><!-- 은퇴후수입 -->
        </ur-box-container>

        

        <!-- 데이터 없습니다. S -->
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data nw-no-data" v-else>
          <mo-list-item ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info  nw-info fex-clumn">
                <span class="txtGray">{{infoText}}</span>
                <mo-button @click="fn_openStepInpt(stepButton)" class="ns-btn-round blue">정보입력</mo-button>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- 데이터 없습니다. E -->

        <!-- 라이프맴핑_하단 (이전, 다음) 버튼 영역 S -->
        <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs btn-sldie-area">
          <div class="relative-div btn-slide">
            <mo-button @click="fn_preStep" class="btn-txt btn-ty1">이전</mo-button>
            <mo-button @click="fn_nextStep" class="btn-txt">다음</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>

             
      <!-- 라이프맴핑 결과 E --> 
      <!-- 바텀시트 1:  저장 여부 확인 바텀시트 (닫기버튼 있는 바텀시트 유형) S -->
       <div class="ns-bottom-sheet ty1">   
        <mo-bottom-sheet ref="saveClose">
          <template v-slot:title>라이프맵핑 저장하고 종료하시겠습니까?</template>
          <div class="untact-pop-box mt30 mb50">
            <p class="untact-txt1 txt-center">
              파일은 6개월간 저장되며
              불러오기로 최근라이프맵핑 목록에서
              다시 가져올 수 있습니다.
            </p>
          </div>
           <template v-slot:action>
            <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_openLifeHome" class="ns-btn-round white">종료</mo-button>
                <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_OpenMSPCT711P" class="ns-btn-round blue">저장</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
       </div>
       <!-- 바텀시트 1:  저장여부 확인 바텀시트 (닫기버튼 있는 바텀시트 유형) S -->
       <!-- 바텀시트 2:  종료여부 확인 바텀시트 (닫기버튼 있는 바텀시트 유형) S -->
       <div class="ns-bottom-sheet ty1">   
        <mo-bottom-sheet ref="close">
          <div class="untact-pop-box mt30 mb50">
            <p class="untact-txt1 txt-center">
              라이프맵핑을 종료하시겠습니까?
            </p>
          </div>
          <template v-slot:action>
            <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_cancel" class="ns-btn-round white">취소</mo-button>
                <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_openLifeHome" class="ns-btn-round blue">종료</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
       </div>
       <!-- 바텀시트 2:  종료 여부 확인 바텀시트 (닫기버튼 있는 바텀시트 유형) S -->
       <!-- 바텀시트 3:  기존 파일에 저장여부 확인 바텀시트 (닫기버튼 있는 바텀시트 유형) S -->
       <div class="ns-bottom-sheet ty1">   
        <mo-bottom-sheet ref="save">
          <div class="untact-pop-box mt30 mb50">
            <p class="untact-txt1 txt-center">
              불러왔던 기존 파일에 덮어쓸까요?
            </p>
          </div>
          <template v-slot:action>
            <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_OpenMSPCT711P" class="ns-btn-round white">신규저장</mo-button>
                <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_fileUpdate" class="ns-btn-round blue">덮어쓰기</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
       </div>
       <!-- 바텀시트 3:  기존 파일에 저장여부 확인 바텀시트 (닫기버튼 있는 바텀시트 유형) S -->


      <!-- 라이프맵핑_기본정보 E -->
      <!-- 바텀시트 1:  라이프맵핑_은퇴전 수입정보 입력 바텀시트 (닫기버튼 있는 바텀시트 유형) S (이미지 아이콘 호출이 2번됨)-->
      <div class="ns-bottom-sheet ty1"> 
        <!-- 2022.12.27 닫기버튼 수정 : S -->  
      <mo-bottom-sheet ref="step1" class="closebtn">
        <template v-slot:title>
          은퇴전 수입 정보
        </template>
        <div class="ns-btn-close" @click="fn_closeStep"></div>  <!-- close 버튼 Sample --> 
        <!-- 2022.12.27 닫기버튼 수정 : E //-->
        <mo-validate-watcher ref="watcherStep1">
          <div class="untact-pop-box mt30">
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0">
              <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                <span class="title must sm">월 기본 소득은</span>
                <mo-decimal-field numeric v-model="scrnRtmBefMntBasIncmAmt" @input="fn_setVoVal('scrnRtmBefMntBasIncmAmt')" :rules="validateRuleBasIncmAmt" underline placeholder="500" max-numlength="6"/>
                <span class="input-txt">만원</span>
              </ur-box-container>
              <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                <span class="title sm">월 추가 소득은</span>
                <mo-decimal-field numeric v-model="scrnRtmBefMntAddmIncmAmt" @input="fn_setVoVal('scrnRtmBefMntAddmIncmAmt')" underline placeholder="100" max-numlength="6"/>
                <span class="input-txt">만원</span>
                <span class="txt">임대,이자/배당소득 등 </span>
              </ur-box-container>   
            </ur-box-container>
          </div>
        </mo-validate-watcher>

        <template v-slot:action>
          <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_jumpStep('2')" class="ns-btn-round white">건너뛰기</mo-button>
              <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_openChart" :class="['ns-btn-round blue', step1ChartDisabledYn ? 'mo-button--disabled' : '']">차트보기</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      </div>
      <!--  바텀시트 1:  라이프맵핑_은퇴전 수입정보 입력 바텀시트 (닫기버튼 있는 바텀시트 유형) E -->

      <!-- 바텀시트 2:  라이프맵핑_은퇴전 지출정보 입력 바텀시트 (닫기버튼 있는 바텀시트 유형) S (이미지 아이콘 호출이 2번됨)-->
      <div class="ns-bottom-sheet ty2">
        <!-- 2022.12.27 닫기버튼 수정 : S -->
        <mo-bottom-sheet ref="step2" class="closebtn" >
          <template v-slot:title>
            은퇴전 지출 정보
          </template>
          <div class="ns-btn-close" @click="fn_closeStep"></div>  <!-- close 버튼 Sample --> 
          <!-- 2022.12.27 닫기버튼 수정 : E //-->
          <mo-validate-watcher ref="watcherStep2">
            <div class="untact-pop-box mt30">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0">
                <!-- 월생확비는 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title must sm">월 생활비는</span>
                  <mo-decimal-field numeric v-model="scrnRtmBefMntLvgct" @input="fn_setVoVal('scrnRtmBefMntLvgct')" underline placeholder="" max-numlength="6"/>
                  <span class="input-txt">만원</span>
                </ur-box-container>
                <!-- 대출은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">대출은 </span>
                  <div class="form-add-box">
                    <div class="txt-add-box w40">
                      <mo-decimal-field decimal ref="scrnRtmBefLnPrnc" class="form-input-name" v-model="scrnRtmBefLnPrnc" @input="fn_setVoVal('scrnRtmBefLnPrnc')" :rules="validateRuleRtmBefLnPrnc" @blur="fn_chkVal('1')" no-shake underline placeholder="" max-numlength="2" max-declength="1"/>
                      <span class="input-txt">억원</span>
                    </div>
                    <div class="txt-add-box w60">
                      <span class="input-tit">월 상환</span>
                      <mo-decimal-field numeric ref="scrnRtmBefLnMntRedam" class="form-input-name" v-model="scrnRtmBefLnMntRedam" @input="fn_setVoVal('scrnRtmBefLnMntRedam')" :rules="validateRuleRtmBefLnMntRedam" @blur="fn_chkVal('1')" no-shake underline placeholder="" max-numlength="4"/>
                      <span class="input-txt">만원</span>
                    </div>
                  </div>
                  <span class="txt"> 주택/사업자금,신용대출등 </span>
                </ur-box-container> 
                <!-- 자녀교육 총 비용은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">자녀교육 총 비용은 </span>
                  <div class="form-add-box">
                    <div class="txt-add-box">
                      <span class="input-tit">인당</span>
                      <mo-decimal-field decimal ref="scrnRtmBefChldEduCst" class="form-input-name" v-model="scrnRtmBefChldEduCst" @input="fn_setVoVal('scrnRtmBefChldEduCst')" :rules="validateRuleRtmBefChldEduCst" @blur="fn_chkVal('2')" no-shake underline placeholder="" max-numlength="2" max-declength="1"/>
                      <span class="input-txt">억원</span>
                    </div>
                    <div class="txt-add-box">
                      <mo-decimal-field numeric ref="scrnCurChldFgr" class="form-input-name" v-model="scrnCurChldFgr" @input="fn_setVoVal('scrnCurChldFgr')" :rules="validateCurChldFgr" @blur="fn_chkVal('2')" no-shake underline placeholder="" max-numlength="2"/>
                      <span class="input-txt">명</span>
                    </div>
                  </div>
                  <span class="txt">현재~대학(원) 졸업시까지 예상되는 학자금 </span>
                </ur-box-container>  
                <!-- 이벤트(차량구입/여행 등) 비용은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">이벤트(차량구입/여행 등) 비용은</span>
                  <mo-decimal-field decimal class="form-input-name" v-model="scrnRtmBefEvtAmt" @input="fn_setVoVal('scrnRtmBefEvtAmt')" underline placeholder="" max-numlength="2" max-declength="1"/>
                  <span class="input-txt">억원</span>
                </ur-box-container>   
                <!-- 월 보험료/저축 금액은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">월 보험료/저축 금액은</span>
                  <mo-decimal-field numeric v-model="scrnRtmBefMntInsrSavAmt" @input="fn_setVoVal('scrnRtmBefMntInsrSavAmt')" underline placeholder="" max-numlength="4"/>
                  <span class="input-txt">만원</span>
                </ur-box-container>  
                <!-- 기타 지출 금액은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">기타 지출 금액은</span>
                  <mo-decimal-field numeric v-model="scrnRtmBefEtcExpntAmt" @input="fn_setVoVal('scrnRtmBefEtcExpntAmt')" underline placeholder="" max-numlength="5"/>
                  <span class="input-txt">만원</span>
                </ur-box-container>  
              </ur-box-container>
            </div>
          </mo-validate-watcher>

          <template v-slot:action>
            <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_jumpStep('3')" class="ns-btn-round white">건너뛰기</mo-button>
                <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_openChart" :class="['ns-btn-round blue', step2ChartDisabledYn ? 'mo-button--disabled' : '']">차트보기</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
      </div>
      <!--  바텀시트 2:  라이프맵핑_은퇴전 지출정보 입력 바텀시트 (닫기버튼 있는 바텀시트 유형) E -->

      <!-- 바텀시트 3:  라이프맵핑_은퇴후 지출정보 입력 바텀시트 (닫기버튼 있는 바텀시트 유형) S (이미지 아이콘 호출이 2번됨)-->
      <div class="ns-bottom-sheet ty3">   
        <!-- 2022.12.27 닫기버튼 수정 : S -->
        <mo-bottom-sheet ref="step3" class="closebtn">
          <template v-slot:title>
            은퇴후 지출 정보
          </template>
          <div class="ns-btn-close" @click="fn_closeStep"></div>  <!-- close 버튼 Sample --> 
          <!-- 2022.12.27 닫기버튼 수정 : E //-->

          <mo-validate-watcher ref="watcherStep3">
            <div class="untact-pop-box mt30">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0">
                <!-- 월생확비는 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title must sm">월 예상 생활비는</span>
                  <mo-decimal-field numeric v-model="scrnRtmAftMntLvgct" @input="fn_setVoVal('scrnRtmAftMntLvgct')" underline placeholder="" max-numlength="6"/>
                  <span class="input-txt">만원</span>
                </ur-box-container>
                <!-- 자녀결혼 비용은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">자녀결혼 비용은 </span>
                  <div class="form-add-box">
                    <div class="txt-add-box">
                      <span class="input-tit">인당</span>
                      <mo-decimal-field numeric ref="scrnRtmAftChldMargCst" class="form-input-name" v-model="scrnRtmAftChldMargCst" @input="fn_setVoVal('scrnRtmAftChldMargCst')" :rules="validateRtmAftChldMargCst" @blur="fn_chkVal('4')" no-shake underline placeholder="" max-numlength="5"/>
                      <span class="input-txt">만원</span>
                    </div>
                    <div class="txt-add-box">
                      <mo-decimal-field numeric ref="scrnCurChldFgr2" class="form-input-name" v-model="scrnCurChldFgr2" @input="fn_setVoVal('scrnCurChldFgr2')" :rules="validateCurChldFgr2" @blur="fn_chkVal('4')" no-shake underline placeholder="" max-numlength="2" />
                      <span class="input-txt">명</span>
                    </div>
                  </div>
                </ur-box-container>  
                <!-- 월 예상 의료비는 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">월 예상 의료비는</span>
                  <mo-decimal-field numeric v-model="scrnRtmAftHlthPrm" @input="fn_setVoVal('scrnRtmAftHlthPrm')" underline placeholder="" max-numlength="4"/>
                  <span class="input-txt">만원</span>
                  <span class="txt">노후 의료비 월평균 약 50만원 지출(통계청) </span>
                </ur-box-container>   
                <!-- 월 예상 건강보험료 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">월 예상 건강보험료</span>
                  <mo-decimal-field numeric v-model="scrnRtmAftMntMdexp" @input="fn_setVoVal('scrnRtmAftMntMdexp')" underline placeholder="" max-numlength="3"/>
                  <span class="input-txt">만원</span>
                  <span class="txt">종합과세소득2천만↑ / 재산세과표 3억6천만&소득 1천만 ↑ 시 피부양자 자격 박탈 </span>
                </ur-box-container>  
                <!-- 기타 지출 금액은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">기타 지출 금액은</span>
                  <mo-decimal-field numeric v-model="scrnRtmAftEtcExpntAmt" @input="fn_setVoVal('scrnRtmAftEtcExpntAmt')" underline placeholder="" max-numlength="5"/>
                  <span class="input-txt">만원</span>
                </ur-box-container>  
              </ur-box-container>
            </div>
          </mo-validate-watcher>
          
          <template v-slot:action>
            <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium"  @click="fn_jumpStep('4')" class="ns-btn-round white">건너뛰기</mo-button>
                <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium"  @click="fn_openChart" :class="['ns-btn-round blue', step3ChartDisabledYn ? 'mo-button--disabled' : '']">차트보기</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
      </div>
      <!--  바텀시트 3:  라이프맵핑_은퇴후 지출정보 입력 바텀시트 (닫기버튼 있는 바텀시트 유형) E -->

      <!-- 바텀시트 4:  라이프맵핑_은퇴후 수입정보 입력 바텀시트 (닫기버튼 있는 바텀시트 유형) S (이미지 아이콘 호출이 2번됨)-->
      <div class="ns-bottom-sheet ty4">   
        <!-- 2022.12.27 닫기버튼 수정 : S -->
        <mo-bottom-sheet ref="step4" class="closebtn">
          <template v-slot:title>
            은퇴후 수입 정보
          </template>
          <div class="ns-btn-close" @click="fn_closeStep"></div>  <!-- close 버튼 Sample --> 
          <!-- 2022.12.27 닫기버튼 수정 : E //-->

          <mo-validate-watcher ref="watcherStep4">
            <div class="untact-pop-box mt30">
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0">
                <!-- 월 예상 기본 소득은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">월 예상 기본 소득은 </span>
                  <div class="form-add-box">
                    <div class="txt-add-box">
                      <mo-decimal-field numeric ref="scrnRtmAftMntBasIncmAmt" class="form-input-name" v-model="scrnRtmAftMntBasIncmAmt" @input="fn_setVoVal('scrnRtmAftMntBasIncmAmt')" :rules="validateRtmAftMntBasIncmAmt" @blur="fn_chkVal('3')" no-shake underline placeholder="" max-numlength="6"/>
                      <span class="input-txt">만원을</span>
                    </div>
                    <div class="txt-add-box">
                      <mo-decimal-field numeric ref="scrnRtmAftMntBasIncmPrd" class="form-input-name" v-model="scrnRtmAftMntBasIncmPrd" @input="fn_setVoVal('scrnRtmAftMntBasIncmPrd')" :rules="validateRtmAftMntBasIncmPrd" @blur="fn_chkVal('3')" no-shake underline placeholder="" max-numlength="2"/>
                      <span class="input-txt">년간</span>
                    </div>
                  </div>
                </ur-box-container>  
                <!-- 예상 월 국민연금은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">예상 월 국민연금은</span>
                  <mo-decimal-field numeric v-model="scrnRtmAftMntPanutAmt" @input="fn_setVoVal('scrnRtmAftMntPanutAmt')" underline placeholder="" max-numlength="5"/>
                  <span class="input-txt">만원</span>
                </ur-box-container>
                <!-- 예상 월 개인연금은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">예상 월 개인연금은</span>
                  <mo-decimal-field numeric v-model="scrnRtmAftMntNpensAmt" @input="fn_setVoVal('scrnRtmAftMntNpensAmt')" underline placeholder="" max-numlength="5"/>
                  <span class="input-txt">만원</span>
                </ur-box-container>  
                <!-- 예상 총 퇴직금은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">예상 총 퇴직금은</span>
                  <mo-decimal-field numeric v-model="scrnExptTotRtmAmt" @input="fn_setVoVal('scrnExptTotRtmAmt')" underline placeholder="" max-numlength="6"/>
                  <span class="input-txt">만원</span>
                </ur-box-container>  
                <!-- 추가 월 소득은 S -->
                <ur-box-container alignV="start" componentid="" direction="column" class="add-box txt-add-box">
                  <span class="title sm">추가 월 소득은</span>
                  <mo-decimal-field numeric v-model="scrnRtmAftAddmMntIncmAmt" @input="fn_setVoVal('scrnRtmAftAddmMntIncmAmt')" underline placeholder="" max-numlength="6"/>
                  <span class="input-txt">만원</span>
                  <span class="txt">기초연금, 주택연금, 임대소득 등 </span>
                </ur-box-container>  
              </ur-box-container>
            </div>
          </mo-validate-watcher>

          <template v-slot:action>
            <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="fn_openResult('4')" class="ns-btn-round white">건너뛰기</mo-button>
                <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" @click="fn_openChart" :class="['ns-btn-round blue', step4ChartDisabledYn ? 'mo-button--disabled' : '']">차트보기</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
      </div>
      <!--  바텀시트 4:  라이프맵핑_은퇴후 수입정보 입력 바텀시트 (닫기버튼 있는 바텀시트 유형) E -->


    </ur-box-container>
    <!-- 라이프맴핑 전체 E--> 
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import CtConstants from '@/config/constants/ctConstants'
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import MSPCT711P from '@/ui/ct/MSPCT711P' // 파일저장

  import MSPCT705D from '@/ui/ct/MSPCT705D'
  import MSPCT706D from '@/ui/ct/MSPCT706D'
  import MSPCT707D from '@/ui/ct/MSPCT707D'
  import MSPCT708D from '@/ui/ct/MSPCT708D'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT704M",
    screenId: "MSPCT704M",
    components: {
      MspBottomSelect,
      MSPCT711P,
      MSPCT705D,
      MSPCT706D,
      MSPCT707D,
      MSPCT708D
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      console.log('==== this.$route.params >> ', this.$route.params)
      this.stepButton = this.$route.params.step
      this.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.$route.params.ctLifeMappRsltInfoSVO)

      if(!this['stepInptYn'+this.stepButton]){
        this.fn_openStepInpt(this.stepButton)
      }

    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        ctLifeMappRsltInfoSVO : {
          lifeMappFileId : '', //라이프매핑파일ID
          lifeMappFileNm : '', //라이프매핑파일명
          lifeMappFileDeltYn : '', //라이프매핑파일삭제여부
          tmpFileYn : '', //임시파일여부
          mobslChnlCustNm : '', //모바일영업채널고객명
          mobslChnlCustId : '', //모바일영업채널고객Id
          custCurAge : null, //고객현재연령
          custRtmAge : null, //고객퇴직연령
          custExptLolAge : null, //고객예상수명연령
          rtmBefMntBasIncmAmt : null, //퇴직전월기본소득금액
          rtmBefMntAddmIncmAmt : null, //퇴직전월추가소득금액
          rtmBefMntLvgct : null, //퇴직전월생활비
          rtmBefLnPrnc : null, //퇴직전월대출원금
          rtmBefLnMntRedam : null, //퇴직전대출월상환액
          rtmBefChldEduCst : null, //퇴직전자녀교육비용
          rtmBefEvtAmt : null, //퇴직전이벤트금액
          rtmBefMntInsrSavAmt : null, //퇴직전월보험저축금액
          rtmBefEtcExpntAmt : null, //퇴직전기타지출금액
          rtmBefTotIncomAmt : null, //퇴직전총수입금액
          rtmBefTotExpntAmt : null, //퇴직전총지출금액
          rtmAftMntLvgct : null, //퇴직후월생활비
          rtmAftChldMargCst : null, //퇴직후자녀결혼비용
          curChldFgr : null, //현재자녀수
          rtmAftHlthPrm : null, //퇴직후월의료비
          rtmAftMntMdexp : null, //퇴직후건강보험료
          rtmAftEtcExpntAmt : null, //퇴직후기타지출금액
          rtmAftMntBasIncmAmt : null, //퇴직후월기본소득금액
          rtmAftMntBasIncmPrd : null, //퇴직후월기본소득기간
          rtmAftMntPanutAmt : null, //퇴직후월국민연금금액
          rtmAftMntNpensAmt : null, //퇴직후월개인연금금액
          exptTotRtmAmt : null, //예상총퇴직금액
          rtmAftAddmMntIncmAmt : null, //퇴직후추가월소득금액
          rtmAftTotExpntAmt : null, //퇴직후총수입금액
          rtmAftTotIncomAmt : null //퇴직후총지출금액
        },
        stepButton: null,
        riskOnOff:false,
        popupCT711P: '', // 파일저장
        poptest: '',
        noData: false,
        infoText:'',

        // validation step1
        validateRuleBasIncmAmt: [
          v => !!v || '필수입력항목입니다.'
        ],

        // validation step2
        validYnRtmBefLnPrnc : false,
        validYnRtmBefLnMntRedam : false,
        validYnRtmBefChldEduCst : false,
        validYnCurChldFgr : false,
        
        validateRuleRtmBefLnPrnc: [
          v => !this.validYnRtmBefLnPrnc || '대출,상환 동시입력항목입니다.'
        ],
        validateRuleRtmBefLnMntRedam: [
          v => !this.validYnRtmBefLnMntRedam || '대출,상환 동시입력항목입니다.'
        ],
        validateRuleRtmBefChldEduCst: [
          v => !this.validYnRtmBefChldEduCst || '비용,인원 동시입력항목입니다.'
        ],
        validateCurChldFgr: [
          v => !this.validYnCurChldFgr || '비용,인원 동시입력항목입니다.'
        ],

        // validation step3
        validYnRtmAftChldMargCst : false,
        validYnCurChldFgr2 : false,

        validateRtmAftChldMargCst: [
          v => !this.validYnRtmAftChldMargCst || '비용,인원 동시입력항목입니다.'
        ],
        validateCurChldFgr2: [
          v => !this.validYnCurChldFgr2 || '비용,인원 동시입력항목입니다.'
        ],

        // validation step4
        validYnRtmAftMntBasIncmAmt : false,
        validYnRtmAftMntBasIncmPrd : false,

        validateRtmAftMntBasIncmAmt: [
          v => !this.validYnRtmAftMntBasIncmAmt || '기본소득,기간 동시입력항목입니다.'
        ],
        validateRtmAftMntBasIncmPrd: [
          v => !this.validYnRtmAftMntBasIncmPrd || '기본소득,기간 동시입력항목입니다.'
        ],


        // 화면용 변수
        step1ChartDisabledYn : true,
        step2ChartDisabledYn : true,
        step3ChartDisabledYn : true,
        step4ChartDisabledYn : true,
        
        // setp1
        scrnRtmBefMntBasIncmAmt : '',   // 은퇴전월기본소득
        scrnRtmBefMntAddmIncmAmt : '',  // 은퇴전월추가소득

        // step2
        scrnRtmBefMntLvgct: '',     // 은퇴전월생활비
        scrnRtmBefLnPrnc: '',       // 은퇴전대출금
        scrnRtmBefLnMntRedam: '',   // 은퇴전월상환금
        scrnRtmBefChldEduCst: '',   // 은퇴전자녀교육비용
        scrnCurChldFgr: '',         // 현재자녀수
        scrnRtmBefEvtAmt: '',       // 은퇴전이벤트비용
        scrnRtmBefMntInsrSavAmt: '',  // 은퇴전월보험료/저축금
        scrnRtmBefEtcExpntAmt: '',    // 은퇴전기타지출금

        // step3
        scrnRtmAftMntLvgct: '',     // 은퇴후월생활비
        scrnRtmAftChldMargCst: '',     // 자녀결혼비용
        scrnCurChldFgr2: '',         // 현재자녀수
        scrnRtmAftHlthPrm: '',     // 은퇴후월의료비
        scrnRtmAftMntMdexp: '',     // 은퇴후월건강보험료
        scrnRtmAftEtcExpntAmt: '',     // 은퇴후기타지출금액

        // step4
        scrnRtmAftMntBasIncmAmt: '',     // 은퇴후월기본소득
        scrnRtmAftMntBasIncmPrd: '',     // 은퇴후소득기간
        scrnRtmAftMntPanutAmt: '',     // 은퇴후월국민연금
        scrnRtmAftMntNpensAmt: '',     // 은퇴후월개인연금
        scrnExptTotRtmAmt: '',     // 예상총퇴직금
        scrnRtmAftAddmMntIncmAmt: '',     // 은퇴후추가월소득

      }
    },
    watch: {
      stepButton: function(val){
        if(val == '1'){
          this.infoText = '은퇴전 수입정보를 입력해 주세요.'
        }else if(val == '2'){
          this.infoText = '은퇴전 지출정보를 입력해 주세요.'
        }else if(val == '3'){
          this.infoText = '은퇴후 지출정보를 입력해 주세요.'
        }else if(val == '4'){
          this.infoText = '은퇴후 수입정보를 입력해 주세요.'
        }
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      stepInptYn1() {
        return (!this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.rtmBefMntBasIncmAmt) && !this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.rtmBefMntBasIncmAmt))
      },
      stepInptYn2() {
        return (!this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.rtmBefMntLvgct))
      },
      stepInptYn3() {
        return (!this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.rtmAftMntLvgct))
      },
      stepInptYn4() {
        return ((!this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.rtmAftMntBasIncmAmt) && !this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.rtmAftMntBasIncmPrd))
            || !this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.rtmAftMntPanutAmt)
            || !this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.rtmAftMntNpensAmt)
            || !this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.exptTotRtmAmt)
            || !this.$bizUtil.isEmpty(this.ctLifeMappRsltInfoSVO.rtmAftAddmMntIncmAmt))
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_back(){
        let tmpFileYn = this.ctLifeMappRsltInfoSVO.tmpFileYn
        let lifeMappFileId = this.ctLifeMappRsltInfoSVO.lifeMappFileId
        console.log('============ fn_back tmpFileYn >>> ', tmpFileYn, lifeMappFileId)
        // 임시파일이거나 신규파일이면
        if(tmpFileYn == '1' || tmpFileYn == ''){
          this.$refs.saveClose.open()
        }else{
          this.$refs.close.open()
        }
      },
      /**
       * Function명 : fn_OpenMSPCT711P
       * 설명       : 파일저장
       */
      fn_OpenMSPCT711P () {
        this.popupCT711P = this.$bottomModal.open(MSPCT711P, {
          properties: {
            pCtLifeMappRsltInfoSVO: this.ctLifeMappRsltInfoSVO,
          },
          listeners: {
            onPopupClose: () => {
              this.$bottomModal.close(this.popupCT711P)
            }
          }
        })
      },
      fn_openLifeHome() {
        this.$router.push( { name: 'MSPCT700M' } )
      },
      fn_fileUpdate() {
        console.log('==== fn_save')
        let userId = this.getStore('userInfo').getters.getUserInfo.userId
        console.log('============= userInfo >> ', this.getStore('userInfo'))
        console.log('============= userId >> ', userId)

        let lv_Vm = this

        let pParams =
                  { 
                    userEno: userId,
                    cTLifeMappRsltInfo: lv_Vm.ctLifeMappRsltInfoSVO
                  }
 
        let trnstId = 'txTSSCT70S4' // 라이프매핑결과 상세정보수정
        console.log('============= pParams  >> ', pParams)

        this.post(lv_Vm, pParams, trnstId, 'U').then(function (response) {
          console.log('response ==> ', response)
          if (response.msgComm.msgCd === 'EFWS003') {
            lv_Vm.fn_BottomAlert('저장되었습니다')
          }else{
            lv_Vm.fn_BottomAlert('저장 시 오류가 발생하였습니다. \n코드: [' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc )
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
        * Function명 : fn_BottomAlert
        * 설명       : 알림 팝업 호출
      ******************************************************************************/
      fn_BottomAlert (contents) {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: '',
            content: contents
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.lv_AlertPop)
              this.$router.push( { name: 'MSPCT700M' } )
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.lv_AlertPop)
            }
          }
        })
      },
      fn_cancel() {
        this.$refs.close.close()
      },
      fn_openPdf(param) {
        let lv_vm = this
        let pParams =
        {
          untySlmtClsfCd: param.untySlmtClsfCd, // {untySlmtClsfCd: 'E401'} E402 E403 E404
          untySlmtUseAuthCd: '1%', // 통합영업자료사용권한코드,기본값은 FC // 통합영업자료사용권한코드(전체/FC/AM/LT/GFC/방카)
          moblInqrYn: 'Y' // 모바일에서 호출여부
        }

        // 트랜잭션ID 설정
        let trnstId = 'txTSSSA09S1'
        const _auth = 'S'

        this.post(lv_vm, pParams, trnstId, _auth)
          .then(function (response) {
            if (lv_vm.$commonUtil.isSuccess(response)) {
              console.log('========== fn_openPdf =================', response)
              // 서비스 후처리
              if (response.body !== null && response.body !== '') {
                // 서비스 호출 결과(body)가 있을 경우
                // 응답 데이터 처리
                let reponseBody = response.body
                if(reponseBody.iCCntntBasInfoVO.length > 0){
                  lv_vm.lv_SelectedSingleItem = reponseBody.iCCntntBasInfoVO[0]
                  lv_vm.fn_SaveFOMFile()
                }else{
                  lv_vm.$addSnackbar('PDF 파일이 존재하지 않습니다.')
                }
              } else {
                // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              }
            } else {
              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }, function (error) {
            // 서버 에러 처리
            return Promise.reject(error)
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * Function명 : fn_BtnESaveFile
      * 설명       : 단 건의 첨부파일을 실행(저장/오픈)한다.
      ******************************************************************************/
      fn_SaveFOMFile () {
        console.log('================== process.env.NODE_ENV >> ', process.env.NODE_ENV)
        // NAS에 저장된 PDF 파일 다운로드 시도 후 성공횟수
        // let successCount = 0
        // NAS에 저장된 PDF 파일 다운로드 시도 횟수
        // let tryCount = 0
        // NAS에 저장된 PDF 파일을 다운받기 위한 정보를 저장하기 위한 컬렉션
        // PDF Viewer 실행 전 Native 저장소로 다운로드할 PDF 파일 정보를 저장하는 Array
        // FOM 파일의 경우는 문서변환에 의해 변환된 정보를 담고 있는 VO를 이용
        let savePdfTargetItemList = []
        // PDF Viewer Native에 전달한 파일 목록을 저장하는 변수
        // test.pdf|[테스트],test2.pdf|[테스트2] 와 같은 문자열로 전달
        // test.pdf|[테스트] 와 같이 단일 파일을 보여줄 경우에도 뒤에 ,를 붙여서 파라미터로 전달
        // PDF Viewer를 통해 실행할 파일 목록과 파일 제목 목록을 저장하는 변수 초기화
        // 파일명tssB파일제복tssA파일명2tssB파일제목2tssA와 같은 형식으로 목록 구성
        let viewerTargetFileList = ''

        let selectedUntySlmtItem = this.lv_SelectedSingleItem
        // alert('분류목록결과:::selectedUntySlmtItem 셋팅' + JSON.stringify(selectedUntySlmtItem))
        // 선택자료 유형이 PDF일 경우
        if (selectedUntySlmtItem.pdfFileYn === 'Y') {
          let itemObj = {}
          itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
          itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
          itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
          itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

          savePdfTargetItemList.push(itemObj)
        }
        // Native PDF Viewer 호출 전 PDF 파일을 Native 저장공간(/sli/tss/comm/fmlmt)에 다운로드
        savePdfTargetItemList.forEach(item => {
          if (CtConstants.DEBUG_MODE_CON) window.console.log('■■■■■ TSSSA001M ■■■■■ fn_ChkDocsTrfo savePdfTargetItemList"s item=' + item.eltrnDoctFileNm)
          if (process.env.NODE_ENV === 'development') {
            item.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            item.eltrnDoctFileNm = 'test.pdf'
            item.localFileNm = 'test.pdf'
            item.fileTitle = '[TEST]'
          }
          try {
            console.log('================ item >>>> ', item)
            this.fn_FdpSaveFile(item)
              .then(response => {
                // 파일명과 PDF Viewer에 노출될 제목을 '|'로 결합하여 한 파일에 대한 정보를 구성
                // viewerTargetFileList += response + '|' + item.fileTitle + ','
                viewerTargetFileList += response + 'tssB' + item.fileTitle + 'tssA'
                // viewerTargetFileList += response
                // 단일 선택일 경우는 바로 PDF Viewer 호출
                this.fn_CallPdfViewer(viewerTargetFileList)
              }).catch(error => {
                // 다중 선택일 경우
                // 화면 깜박거림을 방지하기 위한 로딩바를 해제
                this.getStore('progress').getters.getState.isShow = false
                // 오류 팝업 메시지 출력
                this.getStore('confirm').dispatch('ADD', '총 1건 중 1건 저장이 실패하였습니다')
                if (CtConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
              })
          } catch (error) {
            if (CtConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
        })

      },
      /******************************************************************************
      * Function명 : fn_FdpSaveFile
      * 설명        : Native 저장 장소로 실제 PDF 파일을 다운로드
      *              실제 파일 존재 유무는 다음과 같은 URL을 이용 확인 가능
      *              https://vtss.samsunglife.com:8410/filedown.do?path=file.path.fmlmt&filename=/20190122/000300336200252107122109102.pdf&type=pdf
      * Params     : 다운로드할 PDF 정보를 담고있는 개체
      * Return     : N/A
      ******************************************************************************/
      fn_FdpSaveFile (obj) {
        return new Promise((resolve, reject) => {
          // let tempFileName = obj.eltrnDoctFileNm
          // .pdf 확장자가 없을 경우 확장자 첨부
          if (obj.localFileNm.indexOf('.pdf') < 0) {
            obj.localFileNm = obj.localFileNm + '.pdf'
          }
          console.log(' down plugin : ' + this.$commonUtil.isMobile())
          if (this.$commonUtil.isMobile()) {
            window.fdpbridge.exec('downloadPlugin', {
              path: obj.eltrnDoctFilePathNm,
              filename: obj.eltrnDoctFileNm, // src Directory + fileName
              type: 'pdf',
              localpath: '/sli/tss/comm/fmlmt', // local Directory(안내 자료는 fmlmt 폴더 사용)
              localfilename: obj.localFileNm, // local fileName
              rename: 'N' // 파일명 중복에 관계없이 신규 파일 생성 여부
            }, (result) => {
              resolve(result.data)
            }, (result) => {
              reject(result.data)
            })
          } else { // 데스크탑 테스트 용도
            let t_Url = ''
            if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'stage') {
              t_Url = 'http://u-channel.v.samsunglife.kr:9000/BRCH_ATTACH/bizupload/' + obj.eltrnDoctFileNm + '?IS_POPUP=true'
            } else {
              t_Url = 'https://tss.samsunglife.com:8410/filedown.do?' + 'path=' + obj.eltrnDoctFilePathNm + '&filename=' + obj.eltrnDoctFileNm + '&type=pdf'
            }

            console.log(' t_Url : ' + t_Url)
            console.log(' filename : ' + obj.eltrnDoctFileNm)
            // let timeer = setInterval(window.open(t_Url, '_blank'), 5000)
            // clearInterval(timeer)
            window.open(t_Url, '_blank')
            // lv_vm.fn_getEncrKey() // getEncrKey 로컬에서는 암호화키 파일삭제 패스
            // lv_vm.fn_PdfViewerPlugin(lv_vm.fNInsrFnEltrnSignDoctVO) // 4.전자서명 뷰어 실행
          }
        })
      },
      fn_CallPdfViewer (viewerTargetFileList) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
        this.getStore('progress').getters.getState.isShow = false
        let menuAuth = '00000'
        let lv_vm = this
        
        // PDF 뷰어 호출
        window.fdpbridge.exec('callPdfViewerPlugin', {
          path: '/sli/tss/comm/fmlmt',
          menu: menuAuth, // 대메뉴가 "라이브러리" or "사랑On도움방" 일때 안드로이드 앱의 pdf 뷰's "SMS" 메뉴는 않보이게 한다.
          file: viewerTargetFileList // 서버에 올릴때.
          // file: 'EST_20th.pdftssB제목1tssAtest1234567890.pdftssB제목2tssAtest.pdftssB제목3tssAEST_20th2.pdftssB제목4tssAtest-123.pdftssB제목5tssAEST_20th012345678901234567890123456789.pdftssB제목5tssA' // 테스트
        }, function (result) {
          if (SpConstants.DEBUG_MODE_CON) console.log('성공콜백=', result)
          // // 성공콜백
          // // 스마트폰은 SMS 팝업창을 바로 호출하자. 이때 btn1 이고 pdf뷰의 'X'표시를 클릭시는 'cancel' 값이 온다.
          // if (result.data === 'btn1') {
          //   //this.lv_IsSelectedLibraryContent === true 체크해서 SMS발송 막아야함 pk.son
          //   // if (lv_vm.lv_IsSearch) lv_vm.fn_SMSBtnClicked('TSSSP205M')
          //   lv_vm.fn_SMSBtnClicked()
          // }
        }, function (result) {
          // 실패콜백
          if (SpConstants.DEBUG_MODE_CON) console.log('실패콜백=' + result.data)
        })
      },

      fn_openStepInpt(step) {
        this.$refs['step'+step].open()
        this.fn_setScrnData()
      },
      fn_closeStep() { 
        this.$refs['step'+this.stepButton].close()
      },
      fn_openResult(step) {
        let objParams = {}
        objParams.step = step
        objParams.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.ctLifeMappRsltInfoSVO)
        
        this.$router.push( { name: 'MSPCT709M', params : objParams} )
      },
      fn_setScrnData() {
        // 화면용 데이터 셋팅
        this.scrnRtmBefMntBasIncmAmt = this.ctLifeMappRsltInfoSVO.rtmBefMntBasIncmAmt
        this.scrnRtmBefMntAddmIncmAmt = this.ctLifeMappRsltInfoSVO.rtmBefMntAddmIncmAmt

        this.scrnRtmBefMntLvgct = this.ctLifeMappRsltInfoSVO.rtmBefMntLvgct     // 은퇴전월생활비
        this.scrnRtmBefLnPrnc = this.ctLifeMappRsltInfoSVO.rtmBefLnPrnc       // 은퇴전대출금
        this.scrnRtmBefLnMntRedam = this.ctLifeMappRsltInfoSVO.rtmBefLnMntRedam   // 은퇴전월상환금
        this.scrnRtmBefChldEduCst = this.ctLifeMappRsltInfoSVO.rtmBefChldEduCst   // 은퇴전자녀교육비용
        this.scrnCurChldFgr = this.ctLifeMappRsltInfoSVO.curChldFgr         // 현재자녀수
        this.scrnRtmBefEvtAmt = this.ctLifeMappRsltInfoSVO.rtmBefEvtAmt       // 은퇴전이벤트비용
        this.scrnRtmBefMntInsrSavAmt = this.ctLifeMappRsltInfoSVO.rtmBefMntInsrSavAmt  // 은퇴전월보험료/저축금
        this.scrnRtmBefEtcExpntAmt = this.ctLifeMappRsltInfoSVO.rtmBefEtcExpntAmt    // 은퇴전기타지출금

        this.scrnRtmAftMntBasIncmAmt = this.ctLifeMappRsltInfoSVO.rtmAftMntBasIncmAmt     // 은퇴후월기본소득
        this.scrnRtmAftMntBasIncmPrd = this.ctLifeMappRsltInfoSVO.rtmAftMntBasIncmPrd     // 은퇴후소득기간
        this.scrnRtmAftMntPanutAmt = this.ctLifeMappRsltInfoSVO.rtmAftMntPanutAmt     // 은퇴후월국민연금
        this.scrnRtmAftMntNpensAmt = this.ctLifeMappRsltInfoSVO.rtmAftMntNpensAmt     // 은퇴후월개인연금
        this.scrnExptTotRtmAmt = this.ctLifeMappRsltInfoSVO.exptTotRtmAmt     // 예상총퇴직금
        this.scrnRtmAftAddmMntIncmAmt = this.ctLifeMappRsltInfoSVO.rtmAftAddmMntIncmAmt     // 은퇴후추가월소득

        this.scrnRtmAftMntLvgct = this.ctLifeMappRsltInfoSVO.rtmAftMntLvgct     // 은퇴후월생활비
        this.scrnRtmAftChldMargCst = this.ctLifeMappRsltInfoSVO.rtmAftChldMargCst     // 자녀결혼비용
        this.scrnCurChldFgr2 = this.ctLifeMappRsltInfoSVO.curChldFgr         // 현재자녀수
        this.scrnRtmAftHlthPrm = this.ctLifeMappRsltInfoSVO.rtmAftHlthPrm     // 은퇴후월의료비
        this.scrnRtmAftMntMdexp = this.ctLifeMappRsltInfoSVO.rtmAftMntMdexp     // 은퇴후월건강보험료
        this.scrnRtmAftEtcExpntAmt = this.ctLifeMappRsltInfoSVO.rtmAftEtcExpntAmt     // 은퇴후기타지출금액
      },
      fn_setVoVal(scrnCol) {
        let val = this[scrnCol]
        let rtnVal = ''
        let col = this.$bizUtil.replaceAll(scrnCol, 'scrn', '')
        col = col.substring(0, 1).toLowerCase() + col.substring(1)
        let chkCols = ''

        // 은퇴전월기본소득,은퇴전월생활비,은퇴후월생활비
        chkCols = 'scrnRtmBefMntBasIncmAmt|scrnRtmBefMntLvgct|scrnRtmAftMntLvgct'
        if(chkCols.search(scrnCol) > -1){
          if(this.$bizUtil.isEmpty(val) || val == 0){
            this['step' + this.stepButton + 'ChartDisabledYn'] = true
          }else{
            this['step' + this.stepButton + 'ChartDisabledYn'] = false
          }
        }

        // 은퇴후월기본소득,은퇴후소득기간,은퇴후월국민연금,은퇴후월개인연금,예상총퇴직금,은퇴후추가월소득
        chkCols = 'scrnRtmAftMntBasIncmAmt|scrnRtmAftMntBasIncmPrd|scrnRtmAftMntPanutAmt|scrnRtmAftMntNpensAmt|scrnExptTotRtmAmt|scrnRtmAftAddmMntIncmAmt'
        if(chkCols.search(scrnCol) > -1) {
          let cnt = 0
          if(!this.$bizUtil.isEmpty(this.scrnRtmAftMntBasIncmAmt) && this.scrnRtmAftMntBasIncmAmt > 0 
              && !this.$bizUtil.isEmpty(this.scrnRtmAftMntBasIncmPrd) && this.scrnRtmAftMntBasIncmPrd > 0){
              cnt++
          }
          if((!this.$bizUtil.isEmpty(this.scrnRtmAftMntPanutAmt) && this.scrnRtmAftMntPanutAmt > 0)){
            cnt++
          }
          if((!this.$bizUtil.isEmpty(this.scrnRtmAftMntNpensAmt) && this.scrnRtmAftMntNpensAmt > 0)){
            cnt++
          }
          if((!this.$bizUtil.isEmpty(this.scrnExptTotRtmAmt) && this.scrnExptTotRtmAmt > 0)){
            cnt++
          }
          if((!this.$bizUtil.isEmpty(this.scrnRtmAftAddmMntIncmAmt) && this.scrnRtmAftAddmMntIncmAmt > 0)){
            cnt++
          }

          if(cnt > 0){
            this.step4ChartDisabledYn = false
          }else{
            this.step4ChartDisabledYn = true
          }

          // 은퇴후소득기간
          if('scrnRtmAftMntBasIncmPrd' == scrnCol){
            // (예상수명 - 은퇴나이) 보다 작거나 같음
            let incmPrd = (Number(this.ctLifeMappRsltInfoSVO.custExptLolAge) - Number(this.ctLifeMappRsltInfoSVO.custRtmAge))
            if(Number(val) > incmPrd){
              this.scrnRtmAftMntBasIncmPrd = String(incmPrd)
              return 
            }
          }
        }

        // 은퇴전대출금,은퇴전월상환금,은퇴전자녀교육비용,자녀결혼비용,은퇴후월의료비,은퇴후월건강보험료,은퇴후월기본소득,은퇴후소득기간,은퇴후월국민연금,은퇴후월개인연금,예상총퇴직금,은퇴후추가월소득
        chkCols = 'scrnRtmBefLnPrnc|scrnRtmBefLnMntRedam|scrnRtmBefChldEduCst|scrnRtmAftChldMargCst|scrnRtmAftHlthPrm|scrnRtmAftMntMdexp|scrnRtmAftMntBasIncmAmt|scrnRtmAftMntBasIncmPrd|scrnRtmAftMntPanutAmt|scrnRtmAftMntNpensAmt|scrnExptTotRtmAmt|scrnRtmAftAddmMntIncmAmt'
        if(chkCols.search(scrnCol) > -1){
          if(val == 0){
            this[scrnCol] = null
          }
        }

        // 현재자녀수
        chkCols = 'scrnCurChldFgr|scrnCurChldFgr2'
        if(chkCols.search(scrnCol) > -1){
          if(val == 0){
            this.scrnCurChldFgr = null
            this.scrnCurChldFgr2 = null
          }
        }

        if(!this.$bizUtil.isEmpty(val)) rtnVal = this.$bizUtil.replaceAll(val, ',', '')
        this.ctLifeMappRsltInfoSVO[col] = rtnVal

      },
      fn_preStep() {
        let objParams = {}
        objParams.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.ctLifeMappRsltInfoSVO)

        if(Number(this.stepButton) > 1){
          objParams.step = Number(this.stepButton) - 1
          this.stepButton = String(objParams.step)
        }
        
        if(!this['stepInptYn'+objParams.step]){
          if(this.stepButton == '1'){
            this.$router.push( { name: 'MSPCT703M', params : objParams} )
          }else{
            this.fn_openStepInpt(this.stepButton)
          }
        }
      },
      fn_nextStep() {
        let targetNm = 'MSPCT703M'
        let objParams = {}
        objParams.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.ctLifeMappRsltInfoSVO)

        if(this.stepButton == '4'){
          targetNm = 'MSPCT709M'
          objParams.step = this.stepButton
          this.$router.push( { name: targetNm, params : objParams} )

        }else if(Number(this.stepButton) < 4){
          objParams.step = Number(this.stepButton) + 1
          this.stepButton = String(objParams.step)

          if(!this['stepInptYn'+objParams.step]){
            this.fn_openStepInpt(this.stepButton)
          }
        }
      },
      fn_jumpStep(step) { 
        if(this.stepButton != null){
          this.fn_closeStep()
        }
        this.$refs['step'+step].open()
        this.stepButton = step
        // 임시저장
        this.fn_tmpSave()
      },
      fn_openChart() {
        // objParams.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.ctLifeMappRsltInfoSVO)

        // 임시저장
        this.fn_tmpSave()

        this.fn_closeStep()
      },
      fn_tmpSave() {
        let lifeMappFileId = this.ctLifeMappRsltInfoSVO.lifeMappFileId
        let tmpFileYn = this.ctLifeMappRsltInfoSVO.tmpFileYn

        if(this.$bizUtil.isEmpty(lifeMappFileId) || tmpFileYn == '1'){
          if(this.$bizUtil.isEmpty(lifeMappFileId)){
            console.log('임시 신규')
            // 임시 신규
            this.fn_tmpNewSave()
          }else{
            console.log('임시 수정')
            // 임시 수정
            this.fn_tmpFileUpdate()
          }
        }
      },
      fn_tmpNewSave() {
        let userId = this.getStore('userInfo').getters.getUserInfo.userId
        let lv_vm = this

        let pParams =
                  { 
                    userEno: userId,
                    cTLifeMappRsltInfo: lv_vm.ctLifeMappRsltInfoSVO
                  }
 
        let trnstId = 'txTSSCT70S3' // 라이프매핑결과 상세정보저장
        console.log('============= pParams  >> ', pParams)

        this.post(lv_vm, pParams, trnstId, 'I').then(function (response) {
          console.log('response ==> ', response)
          if (response.msgComm.msgCd === 'EFWS003') {
            if(response.body.cTLifeMappRsltInfo != null && response.body.cTLifeMappRsltInfo != undefined){
              lv_vm.ctLifeMappRsltInfoSVO.lifeMappFileId = response.body.cTLifeMappRsltInfo.lifeMappFileId
            }
          }else{
            let toastMsg = '임시저장 오류.'
            lv_vm.$addSnackbar(toastMsg)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      fn_tmpFileUpdate() {
        let userId = this.getStore('userInfo').getters.getUserInfo.userId
        let lv_vm = this

        let pParams =
                  { 
                    userEno: userId,
                    cTLifeMappRsltInfo: lv_vm.ctLifeMappRsltInfoSVO
                  }
 
        let trnstId = 'txTSSCT70S4' // 라이프매핑결과 상세정보수정
        console.log('============= pParams  >> ', pParams)

        this.post(lv_vm, pParams, trnstId, 'U').then(function (response) {
          console.log('response ==> ', response)
          if (response.msgComm.msgCd === 'EFWS003') {
          }else{
            let toastMsg = '임시저장 오류.'
            lv_vm.$addSnackbar(toastMsg)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      // 대출,상환 동시입력 체크
      fn_chkVal(dvcd) {
        let col1 = ''
        let col2 = ''
        if(dvcd == '1'){
          col1 = 'RtmBefLnPrnc'
          col2 = 'RtmBefLnMntRedam'
        }else if(dvcd == '2'){
          col1 = 'RtmBefChldEduCst'
          col2 = 'CurChldFgr'
        }else if(dvcd == '3'){
          col1 = 'RtmAftMntBasIncmAmt'
          col2 = 'RtmAftMntBasIncmPrd'
        }else if(dvcd == '4'){
          col1 = 'RtmAftChldMargCst'
          col2 = 'CurChldFgr2'
        }
// console.log(this['validYn'+col1], this['validYn'+col2], this.$refs['scrn'+col1], this.$refs['scrn'+col2])
// console.log('validYn'+col1, 'validYn'+col2, 'scrn'+col1, 'scrn'+col2)
        if(this.$bizUtil.isEmpty(this['scrn'+col1]) && this.$bizUtil.isEmpty(this['scrn'+col2])){
          this['validYn'+col1] = false
          this['validYn'+col2] = false
          this.$refs['scrn'+col1].clearValidation()
          this.$refs['scrn'+col2].clearValidation()
        }else{
          if(this.$bizUtil.isEmpty(this['scrn'+col1]) && !this.$bizUtil.isEmpty(this['scrn'+col2])){
            this['validYn'+col1] = true
            this['validYn'+col2] = false
            this.$refs['scrn'+col1].validate()
            this.$refs['scrn'+col2].clearValidation()
          }else if(!this.$bizUtil.isEmpty(this['scrn'+col1]) && this.$bizUtil.isEmpty(this['scrn'+col2])){
            this['validYn'+col1] = false
            this['validYn'+col2] = true
            this.$refs['scrn'+col1].clearValidation()
            this.$refs['scrn'+col2].validate()
          }else{
            this.$refs['scrn'+col1].clearValidation()
            this.$refs['scrn'+col2].clearValidation()
          }
        }
      }

    }
  };
</script>
<style scoped>

</style>


