/*
* 업무구분:
* 화면 명:
* 화면 설명:
* 화면 접근권한:
*/
<template>
  <!-- 라이프맴핑_차트 영역 S -->
  <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="nw-chart-area">
    <div class="chart-title-box fex-clumn">
      <strong class="chart-tit">총 수입</strong>
      <div class="total-box fex-rw">
        <div class="total-sum fex-rw">
          <span class="sun-txt"><b>{{ scrnRtmAftTotExpnt }}</b>억원</span>  
          <div class="btn-edits">
            <mo-button @click="fn_infoEdit" class="btn-edit">정보수정</mo-button>
          </div>
        </div>
        <div class="switch-box">
          <span class="txt">리스크표시</span>
          <mo-switch v-model="riskOnOff" class="ns-switch" labeled/>
        </div>
      </div>
    </div>
    <div class="chart-area fex-clumn">
      <div class="chart-left-amount fex-clumn">
        <!-- 좌측 좌표 값 설정 top: 상단 위치 , center:가운데 위치, bottom: 하단 위치 설정값입니다.) -->
        <span class="top">{{pData.rtmAftMntLvgct * 2}}만원</span>
        <span class="center">{{pData.rtmAftMntLvgct}}만원</span>
        <span class="bottom"> </span>
      </div>
      <div class="chart-graph-type chart-ty1">
        <div class="chart-item-box">
          <!-- 개별 항목: 은퇴 후 총 생활비 S -->
          <div :class="[classOn ? 'chart-item itm-sTy1' : 'chart-item']" v-if="!$bizUtil.isEmpty(pData.rtmAftMntLvgct)">
            <strong class="tit">은퇴 후 총 생활비</strong>
            <div class="item-con fex-rw">
              <span class="total-sum"><b>{{ scrnAftTotLvgct }}</b>억원</span>  
              <span class="detail-item-txt">
                <span class="txt">=</span>
                <span class="item-txt">
                월 <b>{{$bizUtil.numberWithCommas(pData.rtmAftMntLvgct)}}</b>만원
                </span>
                <span class="txt">*</span>
                <span class="item-txt">
                  <b class="fwn">12</b>개월
                </span> 
                <span class="txt">*</span>
                <span class="item-txt">
                  <b>{{ Number(pData.custExptLolAge) - Number(pData.custRtmAge) }}</b>년
                </span>     
              </span>  
            </div>
          </div>
          <!-- 개별 항목: 은퇴 후 총 생활비 E -->
          <!-- 개별 항목: 자녀결혼 S-->
          <div class="chart-item" v-if="!$bizUtil.isEmpty(pData.rtmAftChldMargCst) && !$bizUtil.isEmpty(pData.curChldFgr)">
            <strong class="tit">자녀결혼</strong>
            <div class="item-con fex-rw">
              <span class="total-sum"><b>{{scrnTotChldMargCst}}</b>억원</span>  
              <span class="detail-item-txt">
                <span class="txt">=</span>
                <span class="item-txt">
                <b>{{scrnChldMargCst}}</b>만원
                </span>
                <span class="txt">*</span>
                <span class="item-txt">
                  <b>{{pData.curChldFgr}}</b>명
                </span>     
                
              </span>  
            </div>
          </div>
          <!-- 개별 항목: 자녀결혼 E-->
          <!-- 개별 항목: 의료비 S-->
          <div class="chart-item" v-if="!$bizUtil.isEmpty(pData.rtmAftHlthPrm)">
            <strong class="tit">의료비</strong>
            <div class="item-con fex-rw">
              <span class="total-sum">월<b>{{$bizUtil.numberWithCommas(pData.rtmAftHlthPrm)}}</b>만원</span>  
            </div>
          </div>
          <!-- 개별 항목: 의료비 E-->
          <!-- 개별 항목: 건강보험료 S-->
          <div class="chart-item" v-if="!$bizUtil.isEmpty(pData.rtmAftMntMdexp)">
            <strong class="tit">건강보험료</strong>
            <div class="item-con fex-rw">
              <span class="total-sum">월<b>{{pData.rtmAftMntMdexp}}</b>만원</span>  
            </div>
          </div>
          <!-- 개별 항목: 건강보험료 E-->
        </div>

        <!-- 리스크 영역 : 의료/간병비 리스크 -->
        <div class="chart-risk-box rsk-ty-b" v-if="riskOnOff">
          <a href="#" class="risk-link" @click="fn_openPdf">
            <span class="risk-txt">의료/간병비 리스크</span>
          </a>
        </div>
      </div>
      <div class="chart-bottom-age">
        <span>{{ pData.custRtmAge }}세</span>
        <span>{{ cAge }}세</span>
        <span>{{ pData.custExptLolAge }}세</span>
      </div>
    </div>
  </ur-box-container>
        
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT707D",
    screenId: "MSPCT707D",
    components: {
    },
    props: {
      pData: {
        type: Object
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // 은퇴후총지출
      // ((월생활비 + 월의료비 + 건보료) * 12 * (예상수명 - 은퇴나이) + (자녀결혼인당 * 명수) + 기타지출) * 0.0001
      scrnRtmAftTotExpnt() {
        return (((Number(this.pData.rtmAftMntLvgct) + Number(this.pData.rtmAftHlthPrm) + Number(this.pData.rtmAftMntMdexp)) * 12
          * (Number(this.pData.custExptLolAge) - Number(this.pData.custRtmAge))
          + (Number(this.pData.rtmAftChldMargCst) * Number(this.pData.curChldFgr)) + Number(this.pData.rtmAftEtcExpntAmt)
          ) * 0.0001).toFixed(1)
      },
      cAge() {
        return ((Number(this.pData.custRtmAge) + Number(this.pData.custExptLolAge)) / 2).toFixed(0)
      },
      scrnAftTotLvgct() {
        return ((Number(this.pData.rtmAftMntLvgct) * 12 * (Number(this.pData.custExptLolAge) - Number(this.pData.custRtmAge))) * 0.0001).toFixed(1)
      },
      scrnTotChldMargCst() {
        return (Number(this.pData.rtmAftChldMargCst) * Number(this.pData.curChldFgr) * 0.0001).toFixed(1)
      },
      scrnChldMargCst() {
        if(this.$bizUtil.isEmpty(this.pData.rtmAftChldMargCst)){
          return null
        }else{
          return this.$bizUtil.numberWithCommas(this.pData.rtmAftChldMargCst)
        }
      },

      classOn() {
        if(!this.$bizUtil.isEmpty(this.pData.rtmAftMntLvgct)
            && (this.$bizUtil.isEmpty(this.pData.rtmAftChldMargCst) || this.$bizUtil.isEmpty(this.pData.curChldFgr))
            && this.$bizUtil.isEmpty(this.pData.rtmAftHlthPrm)
            && this.$bizUtil.isEmpty(this.pData.rtmAftMntMdexp)
          ){
          return true
        }else{
          return false
        }
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        riskOnOff:false
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_infoEdit() {
        // let objParams = {}
        // objParams.step = '3'
        // objParams.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.pData)
          
        // this.$router.push( { name: 'MSPCT703M', params : objParams} )
        this.$emit('openStepInpt', '3')
      },
      fn_openPdf() {
        this.$emit('openPdf', {untySlmtClsfCd: 'E403'})
      }
    }

  }
</script>
<style scoped>

</style>


