/*
* 업무구분:
* 화면 명:
* 화면 설명:
* 화면 접근권한:
*/
<template>
  <!-- 라이프맵핑_차트 영역 S -->
  <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="nw-chart-area">
    <div class="chart-title-box fex-clumn">
      <strong class="chart-tit">총 수입</strong>
      <div class="total-box fex-rw">
        <div class="total-sum fex-rw">
          <span class="sun-txt"><b>{{ rtmAftTotIncm }}</b>억원</span>  
          <div class="btn-edits">
            <mo-button @click="fn_infoEdit" class="btn-edit">정보수정</mo-button>
          </div>
        </div>
        <div class="switch-box">
          <span class="txt">리스크표시</span>
          <mo-switch v-model="riskOnOff" class="ns-switch" labeled/>
        </div>
      </div>
    </div>
    <div class="chart-area fex-clumn">
      <div class="chart-left-amount fex-clumn">
        <!-- 좌측 좌표 값 설정 top: 상단 위치 , center:가운데 위치, bottom: 하단 위치 설정값입니다.) -->
        <span class="top">{{ incmAmtTop }}만원</span>
        <span class="center">{{ incmAmt*2 }}만원</span>
        <span class="bottom">{{ incmAmt }}만원</span>
      </div>
      <div class="chart-graph-type">
        <div class="chart-item-box">
          <!-- 개별 항목: 은퇴 후 총 소득 S -->
          <div :class="[classOn ? 'chart-item itm-sTy1' : 'chart-item']" v-if="!$bizUtil.isEmpty(scrnBasIncmAmt) && !$bizUtil.isEmpty(scrnBasIncmPrd)">
            <strong class="tit">은퇴 후 총 소득</strong>
            <div class="item-con fex-rw">
              <span class="total-sum"><b>{{ scrnAftTotIncm }}</b>억원</span>  
              <span class="detail-item-txt">
                <span class="txt">=</span>
                <span class="item-txt">
                월 <b>{{scrnBasIncmAmt}}</b>만원
                </span>
                <span class="txt">*</span>
                <span class="item-txt">
                  <b class="fwn">12</b>개월
                </span> 
                <span class="txt">*</span>
                <span class="item-txt">
                  <b>{{scrnBasIncmPrd}}</b>년
                </span>     
              </span>  
            </div>
          </div> 
          <!-- 개별 항목: 은퇴 후 총 소득 E -->
          <!-- 개별 항목: 국민연금 S-->
          <div :class="[classOn ? 'chart-item itm-sTy1' : 'chart-item']" v-if="!$bizUtil.isEmpty(scrnMntPanutAmt)">
            <strong class="tit">국민연금</strong>
            <div class="item-con fex-rw">
              <span class="total-sum">월<b>{{scrnMntPanutAmt}}</b>만원</span> 
            </div>
          </div>
          <!-- 개별 항목: 국민연금 E-->
          <!-- 개별 항목: 개인연금 S-->
          <div :class="[classOn ? 'chart-item itm-sTy1' : 'chart-item']" v-if="!$bizUtil.isEmpty(scrnMntNpensAmt)">
            <strong class="tit">개인연금</strong>
            <div class="item-con fex-rw">
              <span class="total-sum">월<b>{{scrnMntNpensAmt}}</b>만원</span> 
            </div>
          </div>
          <!-- 개별 항목: 개인연금 E-->
          <!-- 개별 항목: 퇴직금 S-->
          <div :class="[classOn ? 'chart-item itm-sTy1' : 'chart-item']" v-if="!$bizUtil.isEmpty(scrnexptTotRtmAmt)">
            <strong class="tit">퇴직금</strong>
            <div class="item-con fex-rw">
              <span class="total-sum">월<b>{{scrnexptTotRtmAmt}}</b>만원</span> 
            </div>
          </div>
          <!-- 개별 항목: 퇴직금 E-->
          <!-- 개별 항목: 추가소득 S-->
          <div :class="[classOn ? 'chart-item itm-sTy1' : 'chart-item']" v-if="!$bizUtil.isEmpty(scrnAddmMntIncmAmt)">
            <strong class="tit">추가소득</strong>
            <div class="item-con fex-rw">
              <span class="total-sum">월<b>{{scrnAddmMntIncmAmt}}</b>만원</span> 
            </div>
          </div>
          <!-- 개별 항목: 추가소득 E-->
        </div>
        <div class="chart-risk-box" v-if="riskOnOff">
          <a href="#" class="risk-link" @click="fn_openPdf">
            <span class="risk-txt">소득리스크</span>
          </a>
        </div>
      </div>
      <div class="chart-bottom-age">
        <span>{{ pData.custRtmAge }}세</span>
        <span>{{ cAge }}세</span>
        <span>{{ pData.custExptLolAge }}세</span>
      </div>
    </div>
  </ur-box-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT708D",
    screenId: "MSPCT708D",
    components: {
    },
    props: {
      pData: {
        type: Object
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // 은퇴후총소득
      // ((월소득 * 12 * 년수) + (월국민연금 + 월개인연금 + 월추가소득) * 12 * (예상수명 - 은퇴나이) + 총퇴직금) * 0.0001
      rtmAftTotIncm() {
        return (((Number(this.pData.rtmAftMntBasIncmAmt) * 12 * Number(this.pData.rtmAftMntBasIncmPrd))
          + (Number(this.pData.rtmAftMntPanutAmt) + Number(this.pData.rtmAftMntNpensAmt) + Number(this.pData.rtmAftAddmMntIncmAmt)) * 12
          * (Number(this.pData.custExptLolAge) - 65) + Number(this.pData.exptTotRtmAmt)
          ) * 0.0001).toFixed(1)
      }, 
      cAge() {
        return ((Number(this.pData.custRtmAge) + Number(this.pData.custExptLolAge)) / 2).toFixed(0)
      },
      incmAmtTop() {
        return (Number(this.pData.rtmAftMntBasIncmAmt) + Number(this.pData.rtmAftMntPanutAmt) + Number(this.pData.rtmAftMntNpensAmt) + Number(this.pData.rtmAftAddmMntIncmAmt))
      },
      incmAmt() {
        return ((Number(this.pData.rtmAftMntBasIncmAmt) + Number(this.pData.rtmAftMntPanutAmt) + Number(this.pData.rtmAftMntNpensAmt) + Number(this.pData.rtmAftAddmMntIncmAmt)) / 3).toFixed(0)
      },
      scrnAftTotIncm() {
        return ((Number(this.pData.rtmAftMntBasIncmAmt) * 12 * Number(this.pData.rtmAftMntBasIncmPrd)) * 0.0001).toFixed(1)
      },
      scrnBasIncmAmt() {
        if(this.$bizUtil.isEmpty(this.pData.rtmAftMntBasIncmAmt)){
          return null
        }else{
          return this.$bizUtil.numberWithCommas(this.pData.rtmAftMntBasIncmAmt)
        }
      },
      scrnBasIncmPrd() {
        if(this.$bizUtil.isEmpty(this.pData.rtmAftMntBasIncmPrd)){
          return null
        }else{
          return this.$bizUtil.numberWithCommas(this.pData.rtmAftMntBasIncmPrd)
        }
      },
      scrnMntPanutAmt() {
        if(this.$bizUtil.isEmpty(this.pData.rtmAftMntPanutAmt)){
          return null
        }else{
          return this.$bizUtil.numberWithCommas(this.pData.rtmAftMntPanutAmt)
        }
      },
      scrnMntNpensAmt() {
        if(this.$bizUtil.isEmpty(this.pData.rtmAftMntNpensAmt)){
          return null
        }else{
          return this.$bizUtil.numberWithCommas(this.pData.rtmAftMntNpensAmt)
        }
      },
      scrnexptTotRtmAmt() {
        if(this.$bizUtil.isEmpty(this.pData.exptTotRtmAmt)){
          return null
        }else{
          return this.$bizUtil.numberWithCommas(this.pData.exptTotRtmAmt)
        }
      },
      scrnAddmMntIncmAmt() {
        if(this.$bizUtil.isEmpty(this.pData.rtmAftAddmMntIncmAmt)){
          return null
        }else{
          return this.$bizUtil.numberWithCommas(this.pData.rtmAftAddmMntIncmAmt)
        }
      },

      classOn() {
        let cnt = 0
        if(!this.$bizUtil.isEmpty(this.scrnBasIncmAmt) && !this.$bizUtil.isEmpty(this.scrnBasIncmPrd)){
          cnt++
        }
        if(!this.$bizUtil.isEmpty(this.scrnMntPanutAmt)){
          cnt++
        }
        if(!this.$bizUtil.isEmpty(this.scrnMntNpensAmt)){
          cnt++
        }
        if(!this.$bizUtil.isEmpty(this.scrnexptTotRtmAmt)){
          cnt++
        }
        if(!this.$bizUtil.isEmpty(this.scrnAddmMntIncmAmt)){
          cnt++
        }

        if(cnt == 1){
          return true
        }else{
          return false
        }
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        riskOnOff:false
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_infoEdit() {
        // let objParams = {}
        // objParams.step = '4'
        // objParams.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.pData)
        
        // this.$router.push( { name: 'MSPCT703M', params : objParams} )
        this.$emit('openStepInpt', '4')
      },
      fn_openPdf() {
        this.$emit('openPdf', {untySlmtClsfCd: 'E404'})
      }
    }

  }
</script>
<style scoped>

</style>


