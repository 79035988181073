/*
* 업무구분:
* 화면 명:
* 화면 설명:
* 화면 접근권한:
*/
<template>
  <!-- 라이프맴핑_차트 영역 S -->
  <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="nw-chart-area">
    <div class="chart-title-box fex-clumn">
      <strong class="chart-tit">총 지출</strong>
      <div class="total-box fex-rw">
        <div class="total-sum fex-rw">
          <span class="sun-txt"><b>{{ rtmBefTotExpnt }}</b>억원</span>  
          <div class="btn-edits">
            <mo-button @click="fn_infoEdit" class="btn-edit">정보수정</mo-button>
          </div>
        </div>
        <div class="switch-box">
          <span class="txt">리스크표시</span>
          <mo-switch v-model="riskOnOff" class="ns-switch" labeled/>
        </div>
      </div>
    </div>
    <div class="chart-area fex-clumn">
      <div class="chart-left-amount fex-clumn">
          <!-- 좌측 좌표 값 설정 top: 상단 위치 , center:가운데 위치, bottom: 하단 위치 설정값입니다.) -->
        <span class="top">{{ pData.rtmBefMntLvgct * 2 }}만원</span>
        <span class="center">{{ pData.rtmBefMntLvgct }}만원</span>
        <span class="bottom"> </span>
      </div>
      <div class="chart-graph-type chart-ty1">
        <div class="chart-item-box">
          <!-- 개별 항목: 은퇴 전 총 생활비 S -->
          <div :class="[classOn ? 'chart-item itm-sTy1' : 'chart-item']">
            <strong class="tit">은퇴 전 총 생활비</strong>
            <div class="item-con fex-rw">
              <span class="total-sum"><b>{{ rtmBefTotLvgct }}</b>억원</span>  
              <span class="detail-item-txt">
                <span class="txt">=</span>
                <span class="item-txt">
                월 <b>{{ $bizUtil.numberWithCommas(pData.rtmBefMntLvgct) }}</b>만원
                </span>
                <span class="txt">*</span>
                <span class="item-txt">
                  <b class="fwn">12</b>개월
                </span> 
                <span class="txt">*</span>
                <span class="item-txt">
                  <b>{{ Number(pData.custRtmAge) - Number(pData.custCurAge) }}</b>년
                </span>     
              </span>  
            </div>
          </div>
          <!-- 개별 항목: 은퇴 전 총 생활비 E -->
          <!-- 개별 항목: 대출 S-->
          <div class="chart-item" v-if="!$bizUtil.isEmpty(pData.rtmBefLnPrnc) && !$bizUtil.isEmpty(pData.rtmBefLnMntRedam)">
            <strong class="tit">대출</strong>
            <div class="item-con fex-rw">
              <span class="total-sum"><b>{{ pData.rtmBefLnPrnc }}</b>억원</span>  
              <span class="detail-item-txt">
                <span class="txt">=</span>
                <span class="item-txt">
                  월 <b>{{ pData.rtmBefLnMntRedam }}</b>만원
                </span>
              </span>  
            </div>
          </div>
          <!-- 개별 항목: 대출 E-->
          <!-- 개별 항목: 2개씩 배열 (chart-item-half추가): 자녀교육,이벤트 S -->
          <div class="chart-item-half fex-rw">
            <!-- 개별 항목: 자녀교육 S -->
            <div class="chart-item" v-if="!$bizUtil.isEmpty(scrnChldEduCst)">
              <strong class="tit">자녀교육</strong>
              <div class="item-con fex-rw">
                <span class="total-sum"><b>{{ scrnChldEduCst }}</b>억원</span>  
              </div>
            </div>
            <!-- 개별 항목: 자녀교육 E-->
            <!-- 개별 항목: 이벤트(차량/여행) S -->
            <div class="chart-item" v-if="!$bizUtil.isEmpty(scrnEvtAmt)">
              <strong class="tit">이벤트(차량/여행)</strong>
              <div class="item-con fex-rw">
                <span class="total-sum"><b> {{ scrnEvtAmt }}</b>억원</span>  
              </div>
            </div>
            <!-- 개별 항목: 이벤트(차량/여행) E-->
          </div>
          <!-- 개별 항목: 2개씩 배열: 자녀교육,이벤트 E -->
          <!-- 개별 항목: 2개씩 배열 (chart-item-half추가): 보험료,기타 S -->
          <div class="chart-item-half fex-rw">
            <!-- 개별 항목: 보험료/저축 S -->
            <div class="chart-item" v-if="!$bizUtil.isEmpty(scrnInsrSavAmt)">
              <strong class="tit">보험료/저축</strong>
              <div class="item-con fex-rw">
                <span class="total-sum">월<b>{{ scrnInsrSavAmt }}</b>만원</span>  
              </div>
            </div>
            <!-- 개별 항목: 보험료/저축 E-->
            <!-- 개별 항목: 기타지출 S -->
            <div class="chart-item" v-if="!$bizUtil.isEmpty(scrnEtcExpntAmt)">
              <strong class="tit">기타지출</strong>
              <div class="item-con fex-rw">
                <span class="total-sum"><b>{{ scrnEtcExpntAmt }}</b>만원</span>  
              </div>
            </div>
            <!-- 개별 항목: 기타지출 E-->
          </div>
          <!-- 개별 항목: 2개씩 배열일때 E -->
        </div>

        <!-- 리스크 영역 : 질병 리스크 -->
        <div class="chart-risk-box rsk-ty-b" v-if="riskOnOff">
          <a href="#" class="risk-link" @click="fn_openPdf">
            <span class="risk-txt">질병리스크</span>
          </a>
        </div>
      </div>
      <div class="chart-bottom-age">
        <span>{{ pData.custCurAge }}세</span>
        <span>{{ cAge }}세</span>
        <span>{{ pData.custRtmAge }}세</span>
      </div>
    </div>
  </ur-box-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT706D",
    screenId: "MSPCT706D",
    components: {
    },
    props: {
      pData: {
        type: Object
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      // 은퇴전총지출
      // ((월생활비 + 월상환액 + 월보험저축) * 12 * (은퇴나이-현재나이) + 기타지출 + (대출금 + (자녀교육*인원) + 이벤트용) * 10000) * 0.0001
      rtmBefTotExpnt() {
        return (((Number(this.pData.rtmBefMntLvgct) + Number(this.pData.rtmBefLnMntRedam) + Number(this.pData.rtmBefMntInsrSavAmt)) * 12
          * (Number(this.pData.custRtmAge) - Number(this.pData.custCurAge)) + Number(this.pData.rtmBefEtcExpntAmt)
          + (Number(this.pData.rtmBefLnPrnc) + (Number(this.pData.rtmBefChldEduCst) * Number(this.pData.curChldFgr)) + Number(this.pData.rtmBefEvtAmt)) * 10000
          ) * 0.0001).toFixed(1)
      },
      // 은퇴 전 총 생활비
      rtmBefTotLvgct() {
        return (Number(this.pData.rtmBefMntLvgct) * 12 * (Number(this.pData.custRtmAge) - Number(this.pData.custCurAge)) * 0.0001).toFixed(1)
      },
      // 중간나이
      cAge() {
        return ((Number(this.pData.custRtmAge) + Number(this.pData.custCurAge)) / 2).toFixed(0)
      },
      // 자녀교육
      scrnChldEduCst() {
        if(this.$bizUtil.isEmpty(this.pData.rtmBefChldEduCst) || this.$bizUtil.isEmpty(this.pData.curChldFgr)){
          return null
        }else{
          return (Number(this.pData.rtmBefChldEduCst) * Number(this.pData.curChldFgr)).toFixed(1)
        }
      },
      scrnEvtAmt() {
        if(this.$bizUtil.isEmpty(this.pData.rtmBefEvtAmt)){
          return null
        }else{
          return this.$bizUtil.numberWithCommas(this.pData.rtmBefEvtAmt)
        }
      },
      scrnInsrSavAmt() {
        if(this.$bizUtil.isEmpty(this.pData.rtmBefMntInsrSavAmt)){
          return null
        }else{
          return this.$bizUtil.numberWithCommas(this.pData.rtmBefMntInsrSavAmt)
        }
      },
      scrnEtcExpntAmt() {
        if(this.$bizUtil.isEmpty(this.pData.rtmBefEtcExpntAmt)){
          return null
        }else{
          return this.$bizUtil.numberWithCommas(this.pData.rtmBefEtcExpntAmt)
        }
      },

      classOn() {
        if(!this.$bizUtil.isEmpty(this.pData.rtmBefMntLvgct)
            && (this.$bizUtil.isEmpty(this.pData.rtmBefLnPrnc) || this.$bizUtil.isEmpty(this.pData.rtmBefLnMntRedam))
            && this.$bizUtil.isEmpty(this.scrnChldEduCst)
            && this.$bizUtil.isEmpty(this.scrnEvtAmt)
            && this.$bizUtil.isEmpty(this.scrnInsrSavAmt)
            && this.$bizUtil.isEmpty(this.scrnEtcExpntAmt)
          ){
          return true
        }else{
          return false
        }
      }

    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {},
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        riskOnOff: false,
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_infoEdit() {
        // let objParams = {}
        // objParams.step = '2'
        // objParams.ctLifeMappRsltInfoSVO = this.$bizUtil.cloneDeep(this.pData)
          
        // this.$router.push( { name: 'MSPCT703M', params : objParams} )
        this.$emit('openStepInpt', '2')
      },
      fn_openPdf() {
        this.$emit('openPdf', {untySlmtClsfCd: 'E402'})
      }
    }

  }
</script>
<style scoped>

</style>


